<template>
  <div>
    <b-tabs pills nav-class="nav-left">
      <!-- Supplier products-->
      <b-tab v-if="userData.role_name === 'establishment_supplier'" active>
        <template #title>
          <feather-icon
            icon="ShoppingCartIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Proveeduria</span>
        </template>
        <supplier-products />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import UserList from '@/views/user/users-list/UsersList.vue'
import SupplierProducts from '@/views/suppliers/SupplierProducts.vue'


export default {
  components: {
    BTabs,
    BTab,
    UserList,
    SupplierProducts,
  },
  data() {
    return {
      currentEstablishment: null,
      options: {},
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.fixed-text {
  font-size: 13px !important;
  color: $primary !important;
}
</style>
