<template>
  <b-modal
    id="landinPageModal"
    title="Selecciona la acción deseada"
    class="modal-content"
    ok-variant="outline-warning"
    ok-title="Regresar"
    ok-only
    no-close-on-backdrop
    hide-footer
    centered
    body-class="p-2"
  >
    <div class="payments-grid">
      <div class="payment">
        <b-button
          block
          variant="primary"
          size="lg"
          :to="{
            name: 'establishment-configuracion',
            params: { id: establishment.id },
          }"
          class="h-100 w-100 payment-button"
        >
          <span class="mt-1">Configurar</span>
        </b-button>
      </div>

      <div class="payment">
        <b-button
          variant="primary"
          block
          class="h-100 w-100 payment-button"
          :to="{
            name: 'establishment-landing-page',
            params: { slug: slug },
          }"
        >
          <span class="mt-1">Ver sitio web</span>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    establishment: {
      type: Object,
      required: true,
    },
    slug: {
      type: String,
      default: "",
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.custom-btn-color {
  background-color: #7367f0 !important;
  border-color: #7367f0 !important;
}

.payments-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  .payment {
    background-color: #0e1322;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    transition: transform 0.2s ease;

    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }

    @media (min-width: 360px) {
      min-height: 130px;
    }
  }

  @media (min-width: 360px) {
    grid-template-columns: 1fr 1fr;
  }
}

.payment-button {
  font-size: 1.2rem !important;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px !important;
  font-size: 1.1rem !important;

  @media (min-width: 480px) {
    font-size: 1.3rem !important;

    img {
      width: 100px;
    }
  }

  @media (min-width: 768px) {
    font-size: 1.5rem !important;
    padding: 0 !important;
  }

  .payment-icon {
    font-size: 2rem;

    @media (min-width: 480px) {
      font-size: 2.5rem;
    }

    @media (min-width: 768px) {
      font-size: 3rem;
    }
  }
}
</style>
