<template>
  <div>
    <div class="cat-grid">
      <b-card
        v-if="userData.role_name === 'establishment_supplier'"
        @click="
          $router.push({
            name: 'establishment-proveeduria',
            params: { id: $route.params.id },
          })
        "
        data-tour="proveeduria"
        class="cat"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2  name">Proveeduria</div>
          <feather-icon icon="TruckIcon" class="icon" />
        </div>
      </b-card>

      <b-card
        v-if="userData.role_name !== 'establishment_supplier'"
        @click="
          $router.push({
            name: 'establishment-sucursales',
            params: { id: $route.params.id },
          })
        "
        data-tour="sucursales"
        class="cat"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2  name">Tiendas</div>
          <i class="fas fa-store fa-3x mb-50"></i>
        </div>
      </b-card>

      <b-card
        v-if="userData.role_name !== 'establishment_supplier'"
        @click="
          $router.push({
            name: 'establishment-clientes',
            params: { id: $route.params.id },
          })
        "
        data-tour="clientes"
        class="cat"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2  name">Clientes</div>
          <i class="fas fa-user-circle fa-3x mb-50"></i>
        
        </div>
      </b-card>

      <b-card
        v-if="
          (userData.role_name === 'admin' ||
          userData.role_name === 'establishment_admin')
          && currentEstablishment.establishment_type !== 'corporate_education'
        "
        @click="
          $router.push({
            name: 'establishment-proveedores',
            params: { id: $route.params.id },
          })
        "
        data-tour="proveedores"
        class="cat"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2  name">Proveedores</div>
          <i class="fas fa-truck  fa-3x mb-50"></i>
        </div>
      </b-card>

      <b-card
        v-if="
          userData.role_name !== 'establishment_presale_clerk' &&
          userData.role_name !== 'establishment_supplier'
        "
        @click="
          $router.push({
            name: 'establishment-empleados',
            params: { id: $route.params.id },
          })
        "
        data-tour="empleados"
        class="cat"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2  name">Empleados</div>
          <i class="fas fa-users fa-3x mb-50"></i>
        </div>
      </b-card>

      <b-card
        v-if="userData.role_name === 'admin'"
        @click="
          $router.push({
            name: 'establishment-emida',
            params: { id: $route.params.id },
          })
        "
        data-tour="emida"
        class="cat"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2  name">Emida</div>
          <feather-icon icon="DollarSignIcon" class="icon" />
        </div>
      </b-card>

      <b-card
        v-if="userData.role_name === 'admin'"
        @click="
          $router.push({
            name: 'establishment-facturama',
            params: { id: $route.params.id },
          })
        "
        data-tour="facturas"
        class="cat"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2  name">Facturama</div>
          <feather-icon icon="BookOpenIcon" class="icon" />
        </div>
      </b-card>

      <b-card
        v-if="
          userData.role_name !== 'establishment_presale_clerk' &&
          userData.role_name !== 'establishment_supplier'
        "
        @click="
          $router.push({
            name: 'establishment-balances',
            params: { id: $route.params.id },
          })
        "
        data-tour="balance"
        class="cat"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2  name">Saldos</div>
          <i class="fas fa-coins  fa-3x mb-50"></i>
        </div>
      </b-card>

      <b-card
        v-if="
          userData.role_name !== 'establishment_presale_clerk' &&
          userData.role_name !== 'establishment_supplier'
        "
        @click="
          $router.push({
            name: 'establishment-facturas',
            params: { id: $route.params.id },
          })
        "
        data-tour="facturas"
        class="cat"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2  name">Facturas</div>
          <i class="fas fa-file-invoice-dollar fa-3x mb-50"></i>
        </div>
      </b-card>

      <b-card
        v-if="
          userData.role_name === 'establishment_admin' ||
          userData.role_name === 'admin'
        "
        @click="
          $router.push({
            name: 'establishment-stripe',
            params: { id: $route.params.id },
          })
        "
        data-tour="stripe"
        class="cat"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2  name">Pagos electrónicos</div>
          <i class="fas fa-credit-card fa-3x mb-50"></i>
        </div>
      </b-card>

      <b-card
        v-if="
          userData.role_name !== 'establishment_presale_clerk' &&
          userData.role_name !== 'establishment_supplier'
        "
        @click="
          $router.push({
            name: 'establishment-planes',
            params: { id: $route.params.id },
          })
        "
        data-tour="planes"
        class="cat"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2  name">Planes</div>
          <i class="fas fa-calendar-check fa-3x mb-50"></i>
        </div>
      </b-card>

      <b-card
        v-if="
          userData.role_name !== 'establishment_presale_clerk' &&
          userData.role_name !== 'establishment_supplier'
        "
        @click="
          $router.push({
            name: 'establishment-configuracion',
            params: { id: $route.params.id },
          })
        "
        data-tour="configuracion"
        class="cat h-100"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2  name">Configuración</div>
          <i class="fas fa-tools fa-3x mb-50"></i>
        </div>
      </b-card>

      <b-card
        v-if="
          userData.role_name !== 'establishment_presale_clerk' &&
          userData.role_name !== 'establishment_supplier'
        "
        @click="
          handleShowLandingPageModal()
        "
        data-tour="balance"
        class="cat"
      >
        <div
          class="
            h-100
            d-flex
            flex-column
            justify-content-between
            align-items-center
          "
        >
          <div class="mb-2  name">Tienda en línea</div>
          <i class="fas fa-shopping-cart fa-3x mb-50"></i>
        </div>
      </b-card>
    </div>
    <landing-page-modal :slug="currentEstablishment.slug" :establishment="currentEstablishment"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import LandingPageModal from "./LandingPageModal.vue"

export default {
  components: {
    LandingPageModal,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
    }
  },
  computed: {
    ...mapGetters("establishments", ["currentEstablishment"]),
    emidaABalance() {
      let emidaABalance = 0
      if (this.currentEstablishment.id) {
        this.currentEstablishment.accountings.forEach((account) => {
          if (account.account_type === "emida_a") {
            emidaABalance = account.balance
          }
        })
      }
      return emidaABalance
    },
    emidaBBalance() {
      let emidaBBalance = 0
      if (this.currentEstablishment.id) {
        this.currentEstablishment.accountings.forEach((account) => {
          if (account.account_type === "emida_b") {
            emidaBBalance = account.balance
          }
        })
      }
      return emidaBBalance
    },
  },
  methods: {
    handleShowLandingPageModal() {
      this.$bvModal.show("landinPageModal")
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/colors/palette-variables.scss";

.fixed-text {
  font-size: 13px !important;
  color: $primary !important;
}

.cat-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 1rem;
  font-size: 22px;
  font-weight: 800;
  height: 100%;
  cursor: pointer;

  @media screen and (max-width: 1800px) {
    grid-template-columns: repeat(7, 1fr);
    font-size: 18px;
  }

  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(6, 1fr);
    font-size: 18px;
  }

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
    font-size: 18px;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
    font-size: 20px;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(4, 1fr);
    font-size: 18px;
  }

  @media screen and (max-width: 968px) {
    grid-template-columns: repeat(4, 1fr);
    font-size: 15px;
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(2, 1fr);
    font-size: 12px;
  }

  @media screen and (max-width: 300px) {
    grid-template-columns: repeat(1, 1fr);
    font-size: 15px;
  }

  .cat {
    overflow: hidden;
    margin-bottom: 1px;

    &:hover {
      transform: scale(1.05);
      transition: transform 0.2s ease-in-out;
    }
  }

  .icon {
    width: 50px;
    height: 50px;
  }
}
</style>
