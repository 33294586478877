<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          sm="auto"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h3>Productos que surtes...</h3>
        </b-col>
      </b-row>

      <!-- Searchbar -->
      <p>Buscar productos por nombre o código de barras</p>
      <b-input-group>
        <b-form-input
          id="sku"
          v-model="searchQuery"
          variant="outline-primary"
          placeholder="Nombre o Código de barras"
          @input="lookupStoreProducts"
        />
        <b-input-group-append v-if="!searchQuery">
          <b-button
            class="mr-1"
            variant="primary"
            @click="$bvModal.show('new-product-scanner')"
          >
            <i class="fas fa-qrcode" />
          </b-button>
          <b-modal id="new-product-scanner">
            <StreamBarcodeReader
              @decode="onDecode"
            />
          </b-modal>
          <!--
            <b-dropdown
            no-caret
            right
            variant="outline-primary"
          >
            <template #button-content>
              🔧<span class="sr-only">settings</span>
            </template>
            <b-dropdown-item @click="toggleCameraScanner()">
              {{ isCameraScannerActive ? '🎥 Ocultar Camara' : '🎥 Mostrar camara' }}
            </b-dropdown-item>
          </b-dropdown>
          -->
        </b-input-group-append>
        <b-input-group-append v-else>
          <b-button
            variant="outline-warning"
            @click="clearSearchbarAndResetSearch()"
          >
            Borrar
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>

    <!-- Products table -->
    <b-table
      :items="productsShown"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="text-left"
    >
      <!-- Products description -->
      <template #cell(name)="data">
        <b-card-text class="font-weight-bold mb-25 d-flex">
          <b-img
            width="40px"
            height="40px"
            :src="`${data.item.logo}`"
            rounded
            class="mr-1"
          />
          <div>
            {{ data.item.name }}
            <div>
              <b-badge
                v-for="category in data.item.categories_names"
                :key="`category-${category}`"
                variant="light-primary"
                class="font-weight-bold mb-25 d-flex text-center"
              >
                {{ category }}
              </b-badge>
            </div>
          </div>
        </b-card-text>
      </template>

      <!-- Product inventory status -->
      <template #cell(status)="data">
        <app-timeline>
          <app-timeline-item 
            :variant="Number(data.item.needs_attention) == 0 ? 'danger' : Number(data.item.needs_attention) == 1 ? 'warning' : 'success' ">
          </app-timeline-item>
        </app-timeline>
      </template>

      <!-- Details button -->
      <template #cell(details)="row">
        <b-button size="sm" :variant="'primary'" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? 'Ocultar' : 'Ver'}} detalles
        </b-button>
      </template>
      <template #row-details="data">
        <b-card>
          <b-row class="mb-2">
            <b-table
              :items="data.item.store_products_attributes"
              responsive
              :fields="tableColumnsForStoreProducts"
              primary-key="id"
              show-empty
              empty-text="No matching records found"
              class="text-left"
            > 
              <!-- Store name  -->
              <template #cell(name)="store_product">
                <b-card-text 
                  class="font-weight-bold mb-25 d-flex">
                  <b-img
                    width="40px"
                    height="40px"
                    :src="`${store_product.item.store_attributes.logo}`"
                    rounded
                    class="mr-1"
                  />
                  <div>                  
                    <div>
                      <b-badge
                        variant="light-success"
                        class="font-weight-bold mb-25 d-flex text-center"
                      >
                        {{ store_product.item.store_attributes.name }}
                      </b-badge>
                      <div>
                        <b-badge
                          variant="light-warning"
                          class="font-weight-bold mb-25 d-flex text-center"
                        >
                          {{ store_product.item.store_attributes.address_attributes.street }} {{ store_product.item.store_attributes.address_attributes.ext_number }},
                        </b-badge>
                      </div>
                      <div>
                        <b-badge
                          variant="light-warning"
                          class="font-weight-bold mb-25 d-flex text-center"
                        >
                          CP: {{ store_product.item.store_attributes.address_attributes.postal_code }}, {{ store_product.item.store_attributes.address_attributes.city }},
                        </b-badge>
                      </div>
                      <div>
                        <b-badge
                          variant="light-warning"
                          class="font-weight-bold mb-25 d-flex text-center"
                        >
                          {{ store_product.item.store_attributes.address_attributes.state }}, {{ store_product.item.store_attributes.address_attributes.country }}
                        </b-badge>
                      </div>
                    </div>
                  </div>
                  
                </b-card-text>
              </template>

              <!-- Contact -->
              <template #cell(contact)="store_product">
                <b-card-text 
                  class="font-weight-bold mb-25 d-flex">
                  <div>
                    {{ store_product.item.store_attributes.establishment_attributes.contact_name }}
                    <div>
                      <b-badge
                        variant="light-primary"
                        class="font-weight-bold mb-25 d-flex text-center"
                      >
                        {{ store_product.item.store_attributes.establishment_attributes.cel_number? store_product.item.store_attributes.establishment_attributes.cel_number : store_product.item.store_attributes.establishment_attributes.contact_email }}
                      </b-badge>
                    </div>
                  </div>
                </b-card-text>
              </template>

              <!-- Products description -->
              <template #cell(inventory)="store_product">
                  <app-timeline>
                    <b-row>
                    <app-timeline-item 
                      :variant="Number(store_product.item.inventory) <= 5 ? 'danger' : Number(store_product.item.inventory) >= 5 && Number(store_product.item.inventory) <= 10 ? 'warning' : 'success' "
                    >
                      <b-badge
                        :variant="Number(store_product.item.inventory) <= 5 ? 'light-danger' : Number(store_product.item.inventory) >= 5 && Number(store_product.item.inventory) <= 10 ? 'light-warning' : 'light-success' "
                      >
                        {{ store_product.item.inventory }} unidades
                        <store-product-historical-sales :height="100" :width="250" v-if="showHistoricalSales"/>
                      </b-badge>
                      
                    </app-timeline-item>
                    <b-button
                        size="sm" :variant="'primary'" class="mr-2 ml-2"
                        @click="toggleHistoricalSales(store_product.item.historical_inventory)"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-50"
                        />
                        {{ showHistoricalSales ? 'Ocultar' : 'Ver'}}
                      </b-button>
                    </b-row>
                  </app-timeline>
              </template>
            </b-table>
          </b-row>
        </b-card>
      </template>  
      
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <label># de resultados</label>
          <v-select
            v-model="entriesPerPage"
            :dir="'ltr'"
            :options="perPage"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Pagination -->
        <b-col
          v-if="pagination"
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.total_objects"
            :per-page="pagination.per_page"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="(value)=>{handlePagination({ page: value, per_page: pagination.per_page })}"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <b-spinner
                v-if="loading"
                small
                label="Loading"
              />
              <feather-icon
                v-else
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { BCard, BRow, BCol, BTable, BMedia, BAvatar, BLink, BBadge, BPagination, BTooltip,
  BSpinner, BButton,} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debounce } from 'lodash'
import { StreamBarcodeReader } from 'vue-barcode-reader'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import StoreProductHistoricalSales from '../sales/graph-reports/StoreProductHistoricalSales.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BTooltip,
    vSelect,
    BSpinner,
    StreamBarcodeReader,
    ToastificationContent,
    AppTimeline,
    AppTimelineItem,
    StoreProductHistoricalSales,
    // BDropdown,
    // BDropdownItem,
  },
  directives: {
    Ripple,
  },
  props: {
    dates: {
      type: [Array, String],
      default: () => null,
    },
  },
  data() {
    return {
      showHistoricalSales: false,
      searchQuery: '',
      isCameraScannerActive: false,
      loading: false,
      userData: JSON.parse(localStorage.getItem('userData')),   
      entriesPerPage: '10',

      tableColumns: [
        {
          key: 'name',
          label: 'Producto',
        },
        {
          key: 'status',
          label: 'Estado',
        },
        {
          key: 'details',
          label: 'Detalles',
        },
      ],

      tableColumnsForStoreProducts: [
        {
          key: 'name',
          label: 'Sucursal',
        },
        {
          key: 'contact',
          label: 'Contacto',
        },
        {
          key: 'inventory',
          label: 'Inventario',
        },
      ],

      perPage: ['10', '20', '50', '100'],
      perPageForCustomerOrders: ['10', '20', '50', '100'],
    }
  },
  computed: {
    ...mapGetters('stores', ['productsShown','pagination']),
  },
  watch: {
    barcodeScanned(val) {
      this.searchQuery = val
      this.lookupStoreProducts(val)
    },

    entriesPerPage() {
      this.loading = true
      this.loading = true
      this.fetchSupplierProducts({
        meta: {
          pagination: {
            page: this.pagination.page,
            per_page: this.entriesPerPage,
          },
        },
      })
      .then(() => {
        this.loading = false
      })
      .catch(error => {
        this.loading = false
        const msg = error.response.data.messages
        this.$swal({
          title: 'ERROR!',
          text: `${msg}`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
  },

  beforeMount() {
    this.fetchSupplierProducts()
  },

  methods: {
    ...mapActions('stores', ['fetchSupplierProducts',]),
    ...mapMutations('stores', ['setHistoricalInventoryDates', 'setHistoricalInventoryValues']),

    onDecode(result) {
      this.searchQuery = result
      this.$bvModal.hide('new-product-scanner')
    },

    toggleHistoricalSales(data) {
      this.initChartData(data);
      this.showHistoricalSales = !this.showHistoricalSales; 
    },

    initChartData(data) {
      const dataPoints = Object.entries(data).map(([date, value]) => ({
        date,
        value: Number(value),
      }));

      // Sort the dataPoints array in ascending order based on date
      dataPoints.sort((a, b) => new Date(a.date) - new Date(b.date));

      // Extract the sorted dates and values
      const sortedDates = dataPoints.map(dataPoint => dataPoint.date);
      const sortedValues = dataPoints.map(dataPoint => dataPoint.value);

      this.setHistoricalInventoryDates(sortedDates)
      this.setHistoricalInventoryValues(sortedValues)

    },

    lookupStoreProducts: debounce(function searchQuery(query) {
      if (/^\d*$/.test(query) && query != null && query !== '') {
        const barcodeWithOutLastDigit = query.substring(0, query.length - 1)
        this.fetchSupplierProducts({
          by_sku: Number(barcodeWithOutLastDigit),
        }).then(response => {
          if (response.data.length === 1) {
            if (navigator.vibrate) {
              navigator.vibrate(200)
            }
          }
        })
      } else if (query != null && query !== '') {
          this.fetchSupplierProducts({
            by_name: query,
          })
      } else if (query === null || query === '') {
        this.fetchSupplierProducts({ })
      }
    }, 250),

    clearSearchbarAndResetSearch() {
      this.searchQuery = ''
    },

    handlePagination({ page, per_page }) {
      this.loading = true
      this.fetchSupplierProducts({
        meta: {
          pagination: {
            page: this.pagination.page,
            per_page: this.entriesPerPage,
          },
        },
      })
      .then(() => {
        this.loading = false
      })
      .catch(error => {
        this.loading = false
        const msg = error.response.data.messages
        
        this.$swal({
          title: 'ERROR!',
          text: `${msg}`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.search-input{
  max-width: 200px;
}
</style>
